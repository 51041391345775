import React, { CSSProperties } from 'react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import _ from 'lodash';
import { Skeleton } from '../../../../shared/Skeleton/Skeleton';
import { ConfigurationService } from '../../../../../services/ConfigurationService/ConfigurationService';
import NoImagePicture from '../../../../icons/no_image';
import BagIcon from '../../../../icons/bag';
import { ImageAnchor } from '../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { ProductLabels } from '../ProductLabels/ProductLabels';
import { LocalProductMetadata } from '../../../../../services/ProductServices/LocalProduct';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import { ProductVariantDiscount } from '../../../../../provider/cloudshelf/common/ProductSearchResultWithCursor';

export type OnClicked = () => void;

export type BannerType = 'inStock' | 'limitedAvailability' | 'onOrder' | 'soldOut';

export interface ProductCardProps {
    handle: string;
    imageUrl?: string;
    brand?: string;
    title: string;
    price: string;
    originalPrice?: string;
    onClicked: OnClicked;
    bannerType?: BannerType;
    imageWidth: number;
    allowDirectBasketAdd?: boolean;
    onIconClicked?: OnClicked;
    outsideGrid?: boolean;
    metadata: LocalProductMetadata[];
    tags: string[];
    discounts?: ProductVariantDiscount[];
}

const ProductCard: FunctionalComponentWithChildren<ProductCardProps> = React.memo(
    ({
        imageUrl,
        brand,
        title,
        price,
        onClicked,
        originalPrice,
        bannerType,
        imageWidth,
        allowDirectBasketAdd,
        onIconClicked,
        outsideGrid,
        tags,
        metadata,
        discounts,
    }) => {
        const [firstImageLoaded, setFirstImageLoaded] = React.useState<boolean>(false);
        const [firstImageError, setFirstImageError] = React.useState<boolean>(false);
        const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

        const imageContainerStyle: CSSProperties = {
            width: '100%',
            height: '100%',
            minWidth: imageWidth !== 0 ? `${imageWidth}px` : undefined,
            minHeight: imageWidth !== 0 ? `calc(${imageWidth}px - var(--responsive-reference-point)* 0.5)` : undefined,
        };

        const style: CSSProperties = {
            width: '100%',
            height: imageContainerStyle.height,
            minWidth: imageWidth !== 0 ? `${imageWidth}px` : '100%',
            minHeight: imageWidth !== 0 ? `calc(${imageWidth}px - var(--responsive-reference-point)* 0.5)` : '100%',
        };

        const contentAreaSize = imageWidth * 0.22;
        const textAreaGapSize = imageWidth * 0.01;
        const productNameSize = contentAreaSize * 0.35;
        const priceSize = contentAreaSize * 0.3;

        const calculateLineHeight = (fontSize: number) => {
            return `${fontSize * 1.2}px`;
        };

        const displaySoldOutLabel = configService.config()?.displaySoldOutLabel;
        const displayLimitedLabel = configService.config()?.displayLimitedLabel;
        const displayOnOrderLabel = configService.config()?.displayOutOfStockLabel;
        const displayInStockLabel = configService.config()?.displayInStockLabel;

        let stockLabel: string | undefined = undefined;

        if (bannerType === 'soldOut') {
            if (displaySoldOutLabel) {
                stockLabel = _.trim(configService.config()?.soldOutLabel ?? '') || 'Sold out';
            }
        } else if (bannerType === 'limitedAvailability') {
            if (displayLimitedLabel) {
                stockLabel = _.trim(configService.config()?.limitedAvailabilityLabel ?? '') || 'Limited selection';
            }
        } else if (bannerType === 'onOrder') {
            if (displayOnOrderLabel) {
                stockLabel = _.trim(configService.config()?.outOfStockLabel ?? '') || 'On order';
            }
        } else if (bannerType === 'inStock') {
            if (displayInStockLabel) {
                stockLabel = _.trim(configService.config()?.inStockLabel ?? '');
            }
        }

        let objectPos: string | undefined = undefined;

        if (configService.imageAnchor === ImageAnchor.Top) {
            objectPos = 'top';
        } else if (configService.imageAnchor === ImageAnchor.Bottom) {
            objectPos = 'bottom';
        } else if (configService.imageAnchor === ImageAnchor.Left) {
            objectPos = 'left';
        } else if (configService.imageAnchor === ImageAnchor.Right) {
            objectPos = 'right';
        } else if (configService.imageAnchor === ImageAnchor.Center) {
            objectPos = 'center';
        }
        return (
            <>
                <div
                    className={`ProductCard ${outsideGrid ? 'ProductCard__outsideGrid' : ''}`}
                    onClick={onClicked}
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex={0}
                >
                    <div className="ProductCard__imageContainer" style={imageContainerStyle}>
                        {imageUrl !== undefined && !firstImageError ? (
                            <>
                                <div className="ProductCard__image" style={style}>
                                    {!firstImageLoaded && <Skeleton type={'rectangle'} />}
                                    <img
                                        alt=""
                                        src={imageUrl}
                                        style={{
                                            display: firstImageLoaded ? 'inherit' : 'none',
                                            // width: imageWidth !== 0 ? `${imageWidth}px` : '100%',
                                            // height: imageContainerStyle.height,
                                            width: '100%',
                                            height: '100%',
                                            objectFit:
                                                configService.imageAnchor === ImageAnchor.None ? 'contain' : 'cover',
                                            objectPosition: objectPos,
                                        }}
                                        onLoad={() => {
                                            setFirstImageLoaded(true);
                                            setFirstImageError(false);
                                        }}
                                        onError={() => {
                                            setFirstImageLoaded(true);
                                            setFirstImageError(true);
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="ProductCard__image" style={style}>
                                {(firstImageError || imageUrl === undefined) && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '10%',
                                        }}
                                    >
                                        <NoImagePicture />
                                    </div>
                                )}
                            </div>
                        )}
                        {bannerType && stockLabel && <div className="ProductCard__banner">{stockLabel}</div>}
                        <ProductLabels tags={tags} metadata={metadata} discounts={discounts} useLocation />
                    </div>

                    <div className={`ProductCard__content ${outsideGrid ? 'ProductCard__content__outsideGrid' : ''}`}>
                        {/*style={{ height: contentAreaSize }}>*/}
                        <p className="ProductCard__text NeverTranslate" style={{ gap: textAreaGapSize }}>
                            {brand?.trim() !== '' && configService.config()?.productGridIncludeBrand && (
                                <span
                                    className="ProductCard__brand"
                                    style={{
                                        fontSize: productNameSize,
                                        lineHeight: calculateLineHeight(productNameSize),
                                    }}
                                >
                                    {brand}
                                </span>
                            )}
                            <span
                                className="ProductCard__title"
                                style={{ fontSize: productNameSize, lineHeight: calculateLineHeight(productNameSize) }}
                            >
                                {title}
                            </span>
                            <span
                                className="ProductCard__price"
                                style={{ fontSize: priceSize, lineHeight: calculateLineHeight(priceSize) }}
                            >
                                {originalPrice && (
                                    <span
                                        className="ProductCard__saleContainer"
                                        style={{ fontSize: priceSize, lineHeight: calculateLineHeight(priceSize) }}
                                    >
                                        <span className="ProductCard__newPrice">{price}</span>
                                        <span className="ProductCard__originalPrice">{originalPrice}</span>
                                    </span>
                                )}
                                {!originalPrice && <span>{price}</span>}
                            </span>
                        </p>
                    </div>
                </div>
                {allowDirectBasketAdd && (
                    <div
                        className="ProductCard__image__iconspot"
                        onClick={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopPropagation();
                            e.preventDefault();
                            e.nativeEvent.preventDefault();
                            onIconClicked?.();
                        }}
                    >
                        <div className="ProductCard__basketButton">
                            <BagIcon />
                        </div>
                    </div>
                )}
            </>
        );
    },
);

export default ProductCard;
