import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { Container } from 'inversify';
import { List } from 'immutable';
import { AnalyticsService } from '../services/Analytics/AnalyticsService';
import { buildAnalyticsService } from '../services/Analytics/AnalyticsServiceFactory';
import ProductVariantHelperService from '../services/ProductServices/variant/ProductVariantHelperService';
import { buildCloudshelfApolloClient } from '../provider/cloudshelf/client/CloudshelfApolloClientFactory';
import CurrencyService from '../services/CurrencyService/CurrencyService';
import PriceService from '../services/PriceService/PriceService';
import DependencyType from './DependencyType';
import '@sentry/tracing';
import { ProductFilteringService } from '../services/ProductServices/ProductFilteringService';
import { StorageService } from '../services/StorageService/StorageService';
import { EventsService } from '../services/EventsService/EventsService';
import { HandoffService } from '../services/HandoffService/HandoffService';
import { GeoLocationService } from '../services/GeoLocationService/GeoLocationService';
import { CloudshelfTrackedURLService } from '../services/TrackedURLService/CloudshelfTrackedURLService';
import { ConfigurationService } from '../services/ConfigurationService/ConfigurationService';
import { DeviceService } from '../services/ConfigurationService/DeviceService';
import { getDeviceInfo } from '../hooks/UseDeviceInfo';
import { CategoryService } from '../services/CategoryService/CategoryService';
import { SessionManagementService } from '../services/SessionManagementService/SessionManagementService';
import { TileClicksService } from '../services/SessionManagementService/TileClicksService';
import { SentryUtil } from '../utils/Sentry.Util';
import { DisplayOnlyOrchestratorService } from '../services/DisplayOnlyOrchestratorService/DisplayOnlyOrchestratorService';
import * as Sentry from '@sentry/react';
import { CloudflareImageService } from '../services/CloudflareImageService/CloudflareImageService';
import { MenuService } from '../services/MenuService/MenuService';
import { CloudshelfPayloadStatus } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { CheckoutService } from '../services/CheckoutService/CheckoutService';
import { BasketService } from '../services/BasketService/BasketService';
import { ToastService } from '../services/ToastService/ToastService';
import { PaymentDemoService } from '../services/PaymentDemoService/PaymentDemoService';
import { AttractLoopOrchestratorService } from '../services/AttractLoopOrchestratorService/AttractLoopOrchestratorService';

export const dependenciesContainer = new Container();

export const initConfigService = async (): Promise<void> => {
    const backendUrl = process.env.REACT_APP_BACKEND_HOST;

    if (!backendUrl) {
        throw new Error('No backend URL provided');
    }

    dependenciesContainer.bind<ToastService>(DependencyType.ToastService).toConstantValue(new ToastService());

    const storageService = new StorageService();
    dependenciesContainer.bind<StorageService>(DependencyType.StorageService).toConstantValue(storageService);

    dependenciesContainer
        .bind<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient)
        .toConstantValue(buildCloudshelfApolloClient(backendUrl));

    const configService = new ConfigurationService(
        dependenciesContainer.get<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient),
        storageService,
    );

    dependenciesContainer
        .bind<ConfigurationService>(DependencyType.ConfigurationService)
        .toConstantValue(configService);

    const filteringService = new ProductFilteringService(
        dependenciesContainer.get<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient),
        configService,
        storageService,
    );

    dependenciesContainer
        .bind<ProductFilteringService>(DependencyType.ProductFilteringService)
        .toConstantValue(filteringService);

    const deviceService = new DeviceService(
        dependenciesContainer.get<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient),
    );

    dependenciesContainer.bind<DeviceService>(DependencyType.DeviceService).toConstantValue(deviceService);

    storageService.setup();

    try {
        const trans = SentryUtil.StartTransaction('ConfigurationService.RefreshConfig', false);
        await configService.refreshConfig(getDeviceInfo());
        SentryUtil.EndSpan(trans.newTransaction);
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                operationName: 'DependencyInitializer refreshConfig',
            },
        });
    }

    dependenciesContainer.bind<EventsService>(DependencyType.EventsService).toConstantValue(new EventsService());
    dependenciesContainer.bind<MenuService>(DependencyType.MenuService).toConstantValue(new MenuService());
    dependenciesContainer
        .bind<GeoLocationService>(DependencyType.GeoLocationService)
        .toConstantValue(new GeoLocationService());

    const demoService = new PaymentDemoService(
        dependenciesContainer.get<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient),
        configService,
    );

    dependenciesContainer.bind<PaymentDemoService>(DependencyType.PaymentDemoService).toConstantValue(demoService);

    dependenciesContainer
        .bind<AttractLoopOrchestratorService>(DependencyType.AttractLoopOrchestratorService)
        .toConstantValue(
            new AttractLoopOrchestratorService(
                configService,
                dependenciesContainer.get<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient),
                dependenciesContainer.get<EventsService>(DependencyType.EventsService),
            ),
        );
};

export const clearContainer = async (): Promise<void> => {
    const toast = dependenciesContainer.get<ToastService>(DependencyType.ToastService);
    const storage = dependenciesContainer.get<StorageService>(DependencyType.StorageService);
    const apollo = dependenciesContainer.get<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient);
    const config = dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService);
    const deviceService = dependenciesContainer.get<DeviceService>(DependencyType.DeviceService);
    const filtering = dependenciesContainer.get<ProductFilteringService>(DependencyType.ProductFilteringService);
    const events = dependenciesContainer.get<EventsService>(DependencyType.EventsService);
    const menu = dependenciesContainer.get<MenuService>(DependencyType.MenuService);
    const geolocation = dependenciesContainer.get<GeoLocationService>(DependencyType.GeoLocationService);
    const paymentDemoService = dependenciesContainer.get<PaymentDemoService>(DependencyType.PaymentDemoService);
    const attractLoopOrchestratorService = dependenciesContainer.get<AttractLoopOrchestratorService>(
        DependencyType.AttractLoopOrchestratorService,
    );

    dependenciesContainer.unbindAll();

    dependenciesContainer.bind<ToastService>(DependencyType.ToastService).toConstantValue(toast);
    dependenciesContainer.bind<StorageService>(DependencyType.StorageService).toConstantValue(storage);
    dependenciesContainer
        .bind<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient)
        .toConstantValue(apollo);
    dependenciesContainer.bind<ConfigurationService>(DependencyType.ConfigurationService).toConstantValue(config);
    dependenciesContainer
        .bind<ProductFilteringService>(DependencyType.ProductFilteringService)
        .toConstantValue(filtering);
    dependenciesContainer.bind<DeviceService>(DependencyType.DeviceService).toConstantValue(deviceService);
    dependenciesContainer.bind<EventsService>(DependencyType.EventsService).toConstantValue(events);
    dependenciesContainer.bind<MenuService>(DependencyType.MenuService).toConstantValue(menu);
    dependenciesContainer.bind<GeoLocationService>(DependencyType.GeoLocationService).toConstantValue(geolocation);
    dependenciesContainer
        .bind<PaymentDemoService>(DependencyType.PaymentDemoService)
        .toConstantValue(paymentDemoService);
    dependenciesContainer
        .bind<AttractLoopOrchestratorService>(DependencyType.AttractLoopOrchestratorService)
        .toConstantValue(attractLoopOrchestratorService);
};

export const initDependencies = async (): Promise<void> => {
    const configService = dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService);
    const apollo = dependenciesContainer.get<ApolloClient<InMemoryCache>>(DependencyType.ApolloClient);
    const filtering = dependenciesContainer.get<ProductFilteringService>(DependencyType.ProductFilteringService);

    const configStatus = configService.status();

    if (!configStatus) {
        return;
    }

    if (configStatus === CloudshelfPayloadStatus.Frozen) {
        return;
    }

    const configuration = configService.config();
    if (!configuration) {
        throw new Error("Config didn't load");
    }

    window.document.title = configuration.name;

    //Setup the currency service
    CurrencyService.setup();

    dependenciesContainer
        .bind<SessionManagementService>(DependencyType.SessionManagementService)
        .to(SessionManagementService)
        .inSingletonScope();

    dependenciesContainer.bind<AnalyticsService>(DependencyType.AnalyticsService).toDynamicValue(({ container }) => {
        const sessionManagementService = container.get<SessionManagementService>(
            DependencyType.SessionManagementService,
        );

        return buildAnalyticsService(configuration, sessionManagementService);
    });

    dependenciesContainer.bind<PriceService>(DependencyType.PriceService).to(PriceService).inSingletonScope();
    // dependenciesContainer.bind<CurrencyService>(DependencyType.CurrencyService).to(CurrencyService).inSingletonScope();

    dependenciesContainer
        .bind<TileClicksService>(DependencyType.TileClicksService)
        .to(TileClicksService)
        .inSingletonScope();

    dependenciesContainer
        .bind<ProductVariantHelperService>(DependencyType.ProductVariantHelperService)
        .to(ProductVariantHelperService)
        .inSingletonScope();

    dependenciesContainer
        .bind<CategoryService>(DependencyType.CategoryService)
        .toDynamicValue(() => {
            return new CategoryService(List(configService.categories));
        })
        .inSingletonScope();

    dependenciesContainer
        .bind<CloudshelfTrackedURLService>(DependencyType.CloudshelfTrackedURLService)
        .to(CloudshelfTrackedURLService)
        .inSingletonScope();

    dependenciesContainer.bind<CheckoutService>(DependencyType.CheckoutService).to(CheckoutService).inSingletonScope();

    dependenciesContainer.bind<BasketService>(DependencyType.BasketService).to(BasketService).inSingletonScope();

    dependenciesContainer
        .bind<CloudflareImageService>(DependencyType.CloudflareImageService)
        .toDynamicValue(() => new CloudflareImageService(apollo))
        .inSingletonScope();

    dependenciesContainer
        .bind<HandoffService>(DependencyType.HandoffService)
        .toDynamicValue(
            () =>
                new HandoffService(
                    apollo,
                    dependenciesContainer.get<CloudflareImageService>(DependencyType.CloudflareImageService),
                    dependenciesContainer.get<ConfigurationService>(DependencyType.ConfigurationService),
                ),
        )
        .inSingletonScope();

    const price = dependenciesContainer.get<PriceService>(DependencyType.PriceService);

    dependenciesContainer
        .bind<DisplayOnlyOrchestratorService>(DependencyType.DisplayOnlyOrchestratorService)
        .toConstantValue(new DisplayOnlyOrchestratorService(configService, filtering, price, apollo));
};
