import { CheckoutSupportedPaymentMethod } from '../Checkout.Supported.Payment.Method.enum';
import { BaseCheckoutService } from './BaseCheckoutService';
import { PossibleTrackedURL } from '../../TrackedURLService/TrackedURL.type';
import { Basket } from '../../BasketService/Basket.type';
import { Checkout, CheckoutStyle } from '../Checkout.type';
import DependencyType from '../../../dependancyInjection/DependencyType';
import { dependenciesContainer } from '../../../dependancyInjection/DependenciesInitializer';
import { LogUtil } from '../../../utils/Logging.Util';
import { CloudshelfTrackedURLService } from '../../TrackedURLService/CloudshelfTrackedURLService';
import { SessionManagementService } from '../../SessionManagementService/SessionManagementService';
import { reportBasketToCloudshelf } from '../helpers/ReportToCloudshelf';
import { BasketService } from '../../BasketService/BasketService';
import { ConfigurationService } from '../../ConfigurationService/ConfigurationService';
import { StorageService } from '../../StorageService/StorageService';

export class CloudshelfBasketCheckoutService extends BaseCheckoutService {
    private existingCloudshelfOrderId: string | undefined = undefined;

    constructor(
        _basketService: BasketService,
        _configurationService: ConfigurationService,
        _storageService: StorageService,
    ) {
        super(_basketService, _configurationService, CheckoutStyle.QR, _storageService);
    }

    clear() {
        super.clear();
        this.existingCloudshelfOrderId = undefined;
    }

    async handleBasketChange(basket: Basket | undefined): Promise<void> {
        if (!basket) {
            return;
        }

        let newCheckout: Checkout = {
            loading: true,
            appliedCouponCodes: [],
            subTotal: 0,
            total: 0,
        };
        this.propagateChanges(newCheckout);

        this.existingCloudshelfOrderId =
            (await reportBasketToCloudshelf(this.existingCloudshelfOrderId, undefined, basket)) ?? undefined;

        //add up the price of the basket
        let subTotal = 0;
        let total = 0;
        basket.lineItems.forEach(item => {
            subTotal += item.quantity * item.variant.price;
            total += item.quantity * item.variant.price;
        });

        newCheckout = {
            loading: false,
            appliedCouponCodes: [],
            subTotal: subTotal,
            total: total,
            platformProvidedId: this.existingCloudshelfOrderId,
        };
        this.propagateChanges(newCheckout);
    }

    async supportedPaymentMethods(): Promise<CheckoutSupportedPaymentMethod[]> {
        //We don't know what payment methods are supported by the third party accepting the checkout request
        return [];
    }

    async generateURLForOffloadingQR(): Promise<PossibleTrackedURL | undefined> {
        //we need to generate a URL that will be used to offload the QR code

        const untrackedCheckoutURL = this.generatelink(this.basketService.basket);
        if (!untrackedCheckoutURL) {
            return undefined;
        }

        const trackedUrlService = dependenciesContainer.get<CloudshelfTrackedURLService>(
            DependencyType.CloudshelfTrackedURLService,
        );
        const sessionService = dependenciesContainer.get<SessionManagementService>(
            DependencyType.SessionManagementService,
        );
        const trackedURL = await trackedUrlService.generateTrackedURL(
            untrackedCheckoutURL,
            sessionService.sanitizedSessionId,
        );

        if (trackedURL) {
            LogUtil.Log('[CloudshelfBasketTransfer Checkout Service] Tracked URL: ' + trackedURL.url);

            return {
                url: trackedURL.url,
                isTracked: true,
                id: trackedURL.id,
            };
        } else {
            LogUtil.Log('[CloudshelfBasketTransfer Checkout Service]Untracked URL: ' + untrackedCheckoutURL);

            return {
                url: untrackedCheckoutURL,
                isTracked: false,
            };
        }
    }

    generatelink(basket: Basket | undefined): string | undefined {
        if (!basket || basket.lineItems.length === 0) {
            return undefined;
        }

        const checkoutFlow = this.configurationService.checkoutFlow();
        const domain = checkoutFlow.paymentQRCodeDestinationTransferBasketURL;

        if (!domain) {
            return undefined;
        }

        const idAsBase64 = btoa(this.existingCloudshelfOrderId ?? '');

        const url = domain.toLowerCase().replace('{key}', idAsBase64);

        LogUtil.Log('[CloudshelfBasketTransfer Checkout Service] Generated URL: ' + url);
        return url;
    }

    async getBasketId(): Promise<string | undefined> {
        return this.existingCloudshelfOrderId;
    }
}
