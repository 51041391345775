import React, { useEffect, useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useInjection } from '../../../../../dependancyInjection/DependencyContext';
import DependencyType from '../../../../../dependancyInjection/DependencyType';
import Button, { ButtonVariant } from '../../../../shared/inputs/Button/Button';
import { useTranslation } from 'react-i18next';
import StyledText, { TextSize, TextStyle } from '../../../../shared/StyledText/StyledText';
import { CloudshelfTrackedURLService } from '../../../../../services/TrackedURLService/CloudshelfTrackedURLService';
import { SessionManagementService } from '../../../../../services/SessionManagementService/SessionManagementService';
import { SentryUtil } from '../../../../../utils/Sentry.Util';
import { CheckoutService } from '../../../../../services/CheckoutService/CheckoutService';
import { PossibleTrackedURL } from '../../../../../services/TrackedURLService/TrackedURL.type';
import { FunctionalComponentWithChildren } from '../../../../../FCWithChildren';
import PurchaseTick from '../../../../icons/purchase_tick';
import { useHistory } from 'react-router-dom';

export interface PurchaseQrCodeProps {
    onScan: () => void;
    onCancelClearSession: () => void;
    className?: string;
    outerClassName?: string;
}

export const PurchaseQrCode: FunctionalComponentWithChildren<PurchaseQrCodeProps> = ({
    outerClassName = '',
    className = '',
    onScan,
    onCancelClearSession,
}) => {
    const checkoutService = useInjection<CheckoutService>(DependencyType.CheckoutService);
    const cloudshelfTrackedUrlService = useInjection<CloudshelfTrackedURLService>(
        DependencyType.CloudshelfTrackedURLService,
    );
    const qrCodeContainer = useRef<HTMLDivElement>(null);
    const [qrValue, setQrValue] = useState<PossibleTrackedURL>();
    const [showQr, setShowQr] = useState<boolean>(true);
    const [qrIsScanned, setQrIsScanned] = useState<boolean>(false);
    const [countdown, setCountdown] = useState(-1);
    const sessionService = useInjection<SessionManagementService>(DependencyType.SessionManagementService);
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        const { newTransaction } = SentryUtil.StartTransaction('PurchaseQrCode.Generate', false);
        checkoutService
            .generateURLForOffloadingQR()
            .then(urlObject => {
                setQrValue(urlObject);
            })
            .catch()
            .finally(() => {
                SentryUtil.EndSpan(newTransaction);
            });
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        // We can only track cloudshelf QR code scans
        if (qrValue && qrValue.url !== '' && qrValue.isTracked && !qrIsScanned) {
            interval = setInterval(() => {
                const transaction = SentryUtil.StartTransaction('PurchaseQrCode.IsScanned', false);
                cloudshelfTrackedUrlService
                    .isTrackedUrlQRScanned(qrValue.id ?? '')
                    .then(isScanned => {
                        if (isScanned) {
                            setShowQr(false);
                            setQrIsScanned(true);
                            clearInterval(interval);
                            onScan();
                        }
                    })
                    .catch()
                    .finally(() => {
                        SentryUtil.EndSpan(transaction.newTransaction);
                    });
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [qrValue]);

    useEffect(() => {
        if (countdown === 0) {
            void sessionService.endSession();
            history.replace('/');
            // window.location.replace(
            //     `${window.location.origin}${window.location.pathname.substring(
            //         0,
            //         window.location.pathname.indexOf('/', 1),
            //     )}`,
            // );
        }

        if (countdown !== -1) {
            const timeout = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [countdown]);

    useEffect(() => {
        if (qrIsScanned && countdown === -1) {
            setTimeout(() => setCountdown(10), 5000);
        }
    }, [qrIsScanned, countdown]);

    return (
        <>
            <div className={outerClassName}>
                {!showQr && qrIsScanned && countdown === -1 && (
                    <div className="PurchaseQrCode__tick">
                        <PurchaseTick animate={true} useBrandPurchaseColor={true} />
                    </div>
                )}

                {showQr && qrValue && qrValue.url !== '' && (
                    <div className={`PurchaseQrCode ${className}`}>
                        <div className="PurchaseQrCode__container" ref={qrCodeContainer}>
                            <QRCodeSVG value={qrValue.url} />
                        </div>
                    </div>
                )}
            </div>
            {countdown !== -1 && (
                <div className={'PurchaseQrCode__countdown'}>
                    <StyledText style={TextStyle.Subheading} size={TextSize.Large}>
                        {t('session.expiring_soon', {
                            remaining: countdown,
                        })}
                    </StyledText>
                    <br />
                    <Button
                        text={t('session.continue_button')}
                        onClick={onCancelClearSession}
                        variant={ButtonVariant.PRIMARY}
                    />
                </div>
            )}
        </>
    );
};
