import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from '../../../../../shared/Skeleton/Skeleton';
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';
import { LocalProductImage } from '../../../../../../services/ProductServices/LocalProduct';
import NoImagePicture from '../../../../../icons/no_image';
import { useInjection } from '../../../../../../dependancyInjection/DependencyContext';
import { ConfigurationService } from '../../../../../../services/ConfigurationService/ConfigurationService';
import DependencyType from '../../../../../../dependancyInjection/DependencyType';
import { ImageAnchor } from '../../../../../../provider/cloudshelf/graphql/generated/cloudshelf_types';

export interface ProductImageCarouselProps {
    currentImageIndex: number;
    images: LocalProductImage[];
    isExpanded: boolean;
    loading: boolean;
    controlBar?: React.ReactNode | React.ReactNode[];
    setIsExpanded: (isExpanded: boolean) => void;
    scroll: (to: 'variants' | 'customiser' | 'images', blockMessage: boolean) => void;
}

const ProductImageCarousel = React.forwardRef<HTMLElement, ProductImageCarouselProps>((props, reference) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [firstImageLoaded, setFirstImageLoaded] = React.useState<boolean>(false);
    const [imagesCouldNotBeLoaded, setImagesCouldNotBeLoaded] = React.useState<boolean>(false);
    const [images, setImages] = useState<LocalProductImage[]>([]);
    const thumbnailRefs = useRef<HTMLDivElement[]>([]);
    const configService = useInjection<ConfigurationService>(DependencyType.ConfigurationService);

    useEffect(() => {
        setImages(props.images);
    }, [props.images]);

    useEffect(() => {
        if (currentImageIndex !== props.currentImageIndex) {
            setCurrentImageIndex(props.currentImageIndex);
        }
    }, [props.currentImageIndex]);

    useEffect(() => {
        if (images.length > 0) {
            setImagesCouldNotBeLoaded(false);
        } else {
            setImagesCouldNotBeLoaded(true);
        }
    }, [images]);

    useEffect(() => {
        if (thumbnailRefs.current && thumbnailRefs.current[currentImageIndex]) {
            thumbnailRefs.current[currentImageIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            });
        }
    }, [currentImageIndex]);

    useEffect(() => {
        setImagesCouldNotBeLoaded(true);
    }, []);

    const onChangeLocalImageIndex = (index: number) => {
        console.log('Changing Local Image Index', index);
        setCurrentImageIndex(index);
    };

    const firstImage = _.first(images);

    let objectPos: string | undefined = undefined;

    if (configService.imageAnchor === ImageAnchor.Top) {
        objectPos = 'top';
    } else if (configService.imageAnchor === ImageAnchor.Bottom) {
        objectPos = 'bottom';
    } else if (configService.imageAnchor === ImageAnchor.Left) {
        objectPos = 'left';
    } else if (configService.imageAnchor === ImageAnchor.Right) {
        objectPos = 'right';
    } else if (configService.imageAnchor === ImageAnchor.Center) {
        objectPos = 'center';
    }

    let objectFit: string | undefined = undefined;

    if (props.isExpanded) {
        objectFit = 'contain';
    } else {
        objectFit = configService.imageAnchor === ImageAnchor.None ? 'contain' : 'cover';
    }

    return (
        <div className="cloudshelf-carousel">
            <div className="cloudshelf-carousel__container">
                {imagesCouldNotBeLoaded ? (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10%',
                        }}
                    >
                        <NoImagePicture />
                    </div>
                ) : (
                    <>
                        {props.loading || !firstImageLoaded ? (
                            <>
                                <Skeleton type={'rectangle'} />
                                {firstImage !== undefined && (
                                    <img
                                        alt=""
                                        src={firstImage?.url}
                                        style={{ display: 'none' }}
                                        onLoad={() => setFirstImageLoaded(true)}
                                        onError={() => {
                                            console.log('IMAGE ERROR: ', firstImage.url);
                                            //remove this image from the state
                                            const newImages = _.cloneDeep(images);
                                            _.remove(newImages, firstImage);
                                            setImages(newImages);
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <Carousel
                                swipeable
                                emulateTouch
                                dynamicHeight={false}
                                infiniteLoop={true}
                                showThumbs={false}
                                showArrows={false}
                                showStatus={false}
                                showIndicators={false}
                                selectedItem={currentImageIndex}
                                onClickItem={() => {
                                    props.setIsExpanded(!props.isExpanded);
                                    props.scroll('images', props.isExpanded);
                                }}
                                onChange={index => onChangeLocalImageIndex(index)}
                            >
                                {images.map(image => {
                                    return (
                                        <>
                                            {/* <img
                                                key={`${image.url}-background`}
                                                alt=""
                                                src={image.url}
                                                className={`cloudshelf-carousel__background__image`}
                                                // style={{
                                                //     filter: 'blur(8px)',
                                                //     transform: 'scale(1.1)',
                                                //     position: 'absolute',
                                                //     top: 0,
                                                //     left: 0,
                                                //     width: '100%',
                                                //     height: '100%',
                                                //     zIndex: 1,
                                                // }}
                                            /> */}
                                            <img
                                                key={image.url}
                                                alt=""
                                                src={image.url}
                                                className={`cloudshelf-carousel__image ${
                                                    props.isExpanded ? 'cloudshelf-carousel__image__expanded' : ''
                                                }`}
                                                style={{
                                                    position: 'relative',
                                                    zIndex: 2,
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    //@ts-ignore
                                                    objectFit: objectFit,
                                                    objectPosition: objectPos,
                                                }}
                                                onError={() => {
                                                    console.log('IMAGE ERROR: ', image.url);
                                                    //remove this image from the state
                                                    const newImages = _.cloneDeep(images);
                                                    _.remove(newImages, image);
                                                    setImages(newImages);
                                                }}
                                            />
                                        </>
                                    );
                                })}
                            </Carousel>
                        )}
                    </>
                )}
                {props.controlBar && <div className="cloudshelf-carousel__controls">{props.controlBar}</div>}
            </div>
            {firstImageLoaded && images.length > 1 && (
                <div
                    className={`cloudshelf-carousel__thumbnails ${
                        props.isExpanded ? 'cloudshelf-carousel__thumbnails__hidden' : ''
                    } ${imagesCouldNotBeLoaded ? 'cloudshelf-carousel__thumbnails__noPadding' : ''}`}
                    onClick={e => {
                        e.preventDefault();
                        e.nativeEvent.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopPropagation();
                    }}
                >
                    {images.map((item, index) => (
                        <div
                            key={`thumb-${item.url}-${index}`}
                            className={`cloudshelf-carousel__thumbnails__thumb ${
                                currentImageIndex === index ? 'cloudshelf-carousel__thumbnails__thumb__active' : ''
                            }`}
                            onClick={() => {
                                console.log('setting current image', index);
                                onChangeLocalImageIndex(index);
                            }}
                            ref={el => {
                                if (el) {
                                    thumbnailRefs.current[index] = el;
                                }
                            }}
                        >
                            <img src={item.url} alt={`Thumbnail ${index + 1}`} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export default ProductImageCarousel;
