import { inject, injectable } from 'inversify';
import { Observable } from 'react-use/lib/useObservable';
import DependencyType from '../../dependancyInjection/DependencyType';
import { ConfigurationService } from '../ConfigurationService/ConfigurationService';
import { BasketService } from '../BasketService/BasketService';
import { PossibleTrackedURL } from '../TrackedURLService/TrackedURL.type';
import { CheckoutSupportedPaymentMethod } from './Checkout.Supported.Payment.Method.enum';
import { Checkout, CheckoutSessionInfo, CheckoutStyle } from './Checkout.type';
import CurrencyService from '../CurrencyService/CurrencyService';
import { BaseCheckoutService } from './providers/BaseCheckoutService';
import { CloudshelfBasketCheckoutService } from './providers/CloudshelfBasketCheckoutService';
import { Basket } from '../BasketService/Basket.type';
import { ShopifyBasketCheckoutService } from './providers/ShopifyBasketCheckoutService';
import { QrCheckoutDestination } from '../../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { SentryUtil } from '../../utils/Sentry.Util';
import { StorageService } from '../StorageService/StorageService';
import { SalesforceB2CBasketCheckoutService } from './providers/SalesforceB2CBasketCheckoutService';
import { CloudflareImageService } from '../CloudflareImageService/CloudflareImageService';

@injectable()
export class CheckoutService {
    private _platformCheckoutService: BaseCheckoutService;

    constructor(
        @inject(DependencyType.ConfigurationService) private readonly configService: ConfigurationService,
        @inject(DependencyType.BasketService) private readonly basketService: BasketService,
        @inject(DependencyType.StorageService) private readonly storageService: StorageService,
        @inject(DependencyType.CloudflareImageService) private readonly cloudflareImageService: CloudflareImageService,
    ) {
        this._platformCheckoutService = new BaseCheckoutService(
            basketService,
            configService,
            CheckoutStyle.QR,
            storageService,
        );
        this.configure();
    }

    configure() {
        // if (CloudshelfBridge.isAvailable() && CloudshelfBridge.canUseOnDevicePayments()) {
        //     SentryUtil.StartInfoTransaction(
        //         'CheckoutService:Configure:CloudshelfPlayer:Found',
        //         JSON.stringify(window.CloudshelfBridge),
        //     ).newTransaction.finish();

        //     CloudshelfBridge.setCurrentPaymentStatus('idle');
        //     CloudshelfBridge.setPaymentCallbackFunction(async (payload: RequestPaymentResponseCallbackPayload) => {
        //         console.log('[Checkout Service] Cloudshelf Player Payments Payment Response', JSON.stringify(payload));
        //         SentryUtil.StartInfoTransaction(
        //             'CheckoutService:Configure:CloudshelfPlayer:PaymentResponse',
        //             JSON.stringify(payload),
        //         ).newTransaction.finish();

        //         if (payload.success) {
        //             CloudshelfBridge.setCurrentPaymentStatus('payment_success');

        //             const recieptPayload: PrintReceiptPayload = {
        //                 uniqueTransactionId: payload.uniqueTransactionId,
        //                 brand: this.configService.config()?.device?.owner.name ?? 'Unknown Brand',
        //                 address: this.configService.config()?.device?.location?.address ?? 'Unknown Address',
        //                 deviceName: this.configService.config()?.device?.name ?? 'Unknown Device',
        //                 itemLines: [],
        //                 total: this.totalPrice,
        //             };

        //             const lineItems = await this.basketService.getLineItems();

        //             for (const lineItem of lineItems) {
        //                 const payloadLn = {
        //                     qty: lineItem.quantity,
        //                     sku: lineItem.variant.sku,
        //                     description: lineItem.variant.displayName,
        //                     price: CurrencyService.format(lineItem.variant.price),
        //                     notes: [] as string[],
        //                 };

        //                 for (const lineItemAttribute of lineItem.attributes) {
        //                     if (lineItemAttribute.key === 'CLOUDSHELF_UPSELL') {
        //                         payloadLn.notes.push(`This item will be delivered`);
        //                     } else {
        //                         payloadLn.notes.push(
        //                             `${lineItemAttribute.key}: ${
        //                                 this.cloudflareImageService.isCloudflareUrl(lineItemAttribute.value)
        //                                     ? '[Uploaded Image]'
        //                                     : lineItemAttribute.value
        //                             }`,
        //                         );
        //                     }
        //                 }

        //                 recieptPayload.itemLines.push(payloadLn);
        //             }

        //             CloudshelfBridge.printReceipt(recieptPayload);
        //         } else {
        //             CloudshelfBridge.setCurrentPaymentStatus('payment_error');
        //         }
        //     });

        //     this._platformCheckoutService = new CloudshelfPlayerCheckoutService(
        //         this.basketService,
        //         this.configService,
        //         this.storageService,
        //     );
        // } else {
        this._platformCheckoutService = new BaseCheckoutService(
            this.basketService,
            this.configService,
            CheckoutStyle.QR,
            this.storageService,
        );

        const checkoutFlow = this.configService.checkoutFlow();

        if (checkoutFlow.paymentQRCodeDestination === QrCheckoutDestination.Shopify) {
            this._platformCheckoutService = new ShopifyBasketCheckoutService(
                this.basketService,
                this.configService,
                this.storageService,
            );
        } else if (checkoutFlow.paymentQRCodeDestination === QrCheckoutDestination.SalesforceB2C) {
            this._platformCheckoutService = new SalesforceB2CBasketCheckoutService(
                this.basketService,
                this.configService,
                this.storageService,
            );
        } else if (checkoutFlow.paymentQRCodeDestination === QrCheckoutDestination.TransferBasket) {
            this._platformCheckoutService = new CloudshelfBasketCheckoutService(
                this.basketService,
                this.configService,
                this.storageService,
            );
        }
    }
    // }

    clear() {
        this._platformCheckoutService.clear();
    }

    async handleBasketChange(basket: Basket | undefined): Promise<void> {
        return this._platformCheckoutService.handleBasketChange(basket);
    }

    get checkoutStyle(): CheckoutStyle {
        return this._platformCheckoutService.checkoutStyle;
    }
    get supportsCoupons() {
        return this._platformCheckoutService.supportsCoupons;
    }

    async setCouponCode(couponCode: string): Promise<boolean> {
        if (!this._platformCheckoutService.supportsCoupons) {
            return false;
        }

        return this._platformCheckoutService.setCouponCode(couponCode);
    }

    observeCheckout(): Observable<Checkout | undefined> {
        return this._platformCheckoutService.observeCheckout();
    }

    async supportedPaymentMethods(): Promise<CheckoutSupportedPaymentMethod[]> {
        return this._platformCheckoutService.supportedPaymentMethods();
    }

    get loading(): boolean {
        return this._platformCheckoutService.checkout.loading;
    }

    get totalPriceAsNumber(): number {
        return this._platformCheckoutService.checkout.total;
    }
    get totalPrice(): string {
        return CurrencyService.format(this._platformCheckoutService.checkout.total);
    }

    get subTotalPrice(): string {
        return CurrencyService.format(this._platformCheckoutService.checkout.subTotal);
    }

    get discount(): string {
        if (!this._platformCheckoutService.checkout.discount) {
            return '';
        }

        return CurrencyService.format(this._platformCheckoutService.checkout.discount);
    }

    get estimatedTax(): string {
        if (!this._platformCheckoutService.checkout.tax) {
            return '';
        }

        return CurrencyService.format(this._platformCheckoutService.checkout.tax);
    }

    async generateURLForOffloadingQR(): Promise<PossibleTrackedURL | undefined> {
        if (this._platformCheckoutService.checkoutStyle === CheckoutStyle.ON_DEVICE) {
            throw new Error('Cannot generate URL for offloading QR for on device checkout');
        }

        return this._platformCheckoutService.generateURLForOffloadingQR();
    }

    sessionInfo(): CheckoutSessionInfo {
        return {
            price: this._platformCheckoutService.checkout.total,
        };
    }

    async getBasketId(): Promise<string | undefined> {
        return this._platformCheckoutService.getBasketId();
    }
}
