import { Alignment, Size, ThirdPartyOrder } from '../provider/cloudshelf/graphql/generated/cloudshelf_types';
import { Basket } from '../services/BasketService/Basket.type';
import { ensureSpacing } from '../services/ConfigurationService/temp_hardcoded';
import {
    CheckoutFlowAcquisitionOption,
    CloudshelfEngineConfig,
    CloudshelfPrinterBlock,
} from '../services/ConfigurationService/types/config/CloudshelfEngineConfig';
import i18n from '../services/I18n/I18n';
import { AcquisitionOptionUtil } from './AcquisitionOption.Util';
export type RequestPaymentResponseCallbackPayload = {
    success: boolean;
    providerTransactionId?: string;
    uniqueTransactionId?: string;
    extraData?: any;
};

export type RequestPaymentPayload = {
    money: number;
    uniqueTransactionId: string;
};

export type CloudPaymentData = {
    transactionId: string;
    authorizationId: string;
    maskedCardNumber: string;
    cardType: string;
    verificationMethod: string;
    tid: string;
};

export type PrintReceiptPayload = {
    cloudPaymentData?: CloudPaymentData;
    translations: {
        transaction: string;
        authCode: string;
        tid: string;
    };
    lines: PrinterLine[];
};

export type PrinterLine = {
    blockType: 'textContent' | 'paymentDetails' | 'spacer' | 'precut';
    size?: 'small' | 'medium' | 'large';
    alignment?: 'left' | 'center' | 'right';
    textContent?: string;
};

export type tCloudshelfBridgeMiscellaneous = {
    exitEngine: () => void;
};

export type tCloudshelfBridgePaymentState = 'idle' | 'payment_pending' | 'payment_success' | 'payment_error';
export type tCloudshelfBridgePayment = {
    currentStatus: tCloudshelfBridgePaymentState;
    supportsOnDevicePayments: boolean;
    installedPaymentProviders: string[];
    requestPayment: (payload: RequestPaymentPayload) => void;
    requestPaymentResponseCallback: (payload: RequestPaymentResponseCallbackPayload) => void;
};

export type tCloudshelfBridgePrinting = {
    printReceipt: (payload: PrintReceiptPayload) => void;
};

export type tCloudshelfBridgeState = 'uninitialized' | 'initialized' | 'error';
export type tCloudshelfBridge = {
    version: string;
    platform: string;
    state: tCloudshelfBridgeState;
    printing: tCloudshelfBridgePrinting;
    payments: tCloudshelfBridgePayment;
    miscellaneous: tCloudshelfBridgeMiscellaneous;
};

declare global {
    interface Window {
        CloudshelfBridge?: tCloudshelfBridge;
    }
}

class CloudshelfBridgeError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'CloudshelfBridgeError';
    }
}

export class CloudshelfBridge {
    static isAvailable() {
        return window.CloudshelfBridge !== undefined;
    }

    static getPlatform() {
        if (CloudshelfBridge.isAvailable()) {
            return window.CloudshelfBridge!.platform;
        } else {
            throw new CloudshelfBridgeError('Attempted to use getPlatform without CloudshelfBridge being available.');
        }
    }

    static getStatus() {
        if (CloudshelfBridge.isAvailable()) {
            return window.CloudshelfBridge!.state;
        } else {
            throw new CloudshelfBridgeError('Attempted to use getStatus without CloudshelfBridge being available.');
        }
    }

    static exitEngine() {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.miscellaneous.exitEngine();
        } else {
            throw new CloudshelfBridgeError('Attempted to use exitEngine without CloudshelfBridge being available.');
        }
    }

    static printReceipt(payload: PrintReceiptPayload) {
        if (CloudshelfBridge.isAvailable()) {
            // alert('printReceipt isAvailable: ' + JSON.stringify(payload, null, 2));
            window.CloudshelfBridge!.printing.printReceipt(payload);
        }
    }

    static requestPayment(payload: RequestPaymentPayload) {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.payments.requestPayment(payload);
        } else {
            throw new CloudshelfBridgeError(
                'Attempted to use requestPayment without CloudshelfBridge being available.',
            );
        }
    }

    static setPaymentCallbackFunction(callback: (payload: RequestPaymentResponseCallbackPayload) => void) {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.payments.requestPaymentResponseCallback = callback;
        } else {
            throw new CloudshelfBridgeError(
                'Attempted to use setPaymentCallbackFunction without CloudshelfBridge being available.',
            );
        }
    }

    // static getCurrentPaymentStatus() {
    //     return '';
    //     // if (CloudshelfBridge.isAvailable()) {
    //     //     return window.CloudshelfBridge!.currentPaymentStatus;
    //     // }

    //     // throw new CloudshelfBridgeError(
    //     //     'Attempted to use getCurrentPaymentStatus without CloudshelfBridge being available.',
    //     // );
    // }

    static setCurrentPaymentStatus(status: 'idle' | 'payment_pending' | 'payment_success' | 'payment_error') {
        if (CloudshelfBridge.isAvailable()) {
            window.CloudshelfBridge!.payments.currentStatus = status;
        } else {
            throw new CloudshelfBridgeError(
                'Attempted to use setCurrentPaymentStatus without CloudshelfBridge being available.',
            );
        }
    }

    static getVersion() {
        if (CloudshelfBridge.isAvailable()) {
            return window.CloudshelfBridge!.version;
        } else {
            throw new CloudshelfBridgeError('Attempted to use getVersion without CloudshelfBridge being available.');
        }
    }

    static canUseOnDevicePayments() {
        if (CloudshelfBridge.isAvailable()) {
            return window.CloudshelfBridge!.payments.supportsOnDevicePayments ?? false;
        } else {
            throw new CloudshelfBridgeError(
                'Attempted to use canUseOnDevicePayments without CloudshelfBridge being available.',
            );
        }
    }

    static buildPrinterPayload(
        cloudPaymentData: CloudPaymentData | undefined,
        blocks: CloudshelfPrinterBlock[],
        config: CloudshelfEngineConfig,
        salesAssistant: string | undefined,
        checkoutFlowAcquisitionOption: CheckoutFlowAcquisitionOption,
        basket: Basket,
        total: number,
        addAcquisitionOptionAsItem: boolean,
        acquisitionCost: string,
        vivaTransactionId: string,
        shopifyOrder: ThirdPartyOrder | undefined,
    ): PrintReceiptPayload {
        const payload: PrintReceiptPayload = {
            cloudPaymentData,
            translations: {
                transaction: i18n.t('cardPayment.transaction'),
                authCode: i18n.t('cardPayment.authCode'),
                tid: i18n.t('cardPayment.tid'),
            },
            lines: [],
        };

        for (const block of blocks) {
            if (block.printerBlockDiscriminator === 'CLOUDSHELF_DETAILS') {
                const cloudshelfDetails: PrinterLine = {
                    blockType: 'textContent',
                    textContent: `${i18n.t('cardPayment.cloudshelf')}: ${config.name}`,
                    size: 'medium',
                    alignment: 'center',
                };
                payload.lines.push(cloudshelfDetails);
            } else if (block.printerBlockDiscriminator === 'DEVICE_DETAILS') {
                const deviceDetails: PrinterLine = {
                    blockType: 'textContent',
                    textContent: `${i18n.t('cardPayment.device')}: ${
                        config.device?.name ?? 'Preview Mode (No Device)'
                    }`,
                    size: 'medium',
                    alignment: 'center',
                };
                payload.lines.push(deviceDetails);
            } else if (block.printerBlockDiscriminator === 'FULFILMENT_DETAILS') {
                const todoLine: PrinterLine = {
                    blockType: 'textContent',
                    textContent: `${checkoutFlowAcquisitionOption.displayName}`,
                    size: 'medium',
                    alignment: 'center',
                };
                payload.lines.push(todoLine);
            } else if (block.printerBlockDiscriminator === 'LOCATION_DETAILS') {
                const locationDetails: PrinterLine = {
                    blockType: 'textContent',
                    textContent: `${i18n.t('cardPayment.location')}: ${
                        config.device?.location?.name ?? 'Unknown Location'
                    }`,
                    size: 'medium',
                    alignment: 'center',
                };
                payload.lines.push(locationDetails);
            } else if (block.printerBlockDiscriminator === 'ORDER_DETAILS') {
                for (const item of basket.lineItems) {
                    const lineOne: PrinterLine = {
                        blockType: 'textContent',
                        textContent: `${ensureSpacing(item.quantity.toString(), 5)}${ensureSpacing(
                            item.product.title,
                            33,
                        )}${ensureSpacing(item.variant.price.toFixed(2), 10, true)}`,
                        size: 'medium',
                        alignment: 'left',
                    };
                    payload.lines.push(lineOne);
                    const lineTwo: PrinterLine = {
                        blockType: 'textContent',
                        textContent: `${ensureSpacing(' ', 5)}${ensureSpacing(item.variant.displayName, 33)}`,
                        size: 'medium',
                        alignment: 'left',
                    };
                    payload.lines.push(lineTwo);
                    if (item.variant.sku !== undefined && item.variant.sku !== '') {
                        const lineThree: PrinterLine = {
                            blockType: 'textContent',
                            textContent: `${ensureSpacing(' ', 5)}${ensureSpacing(item.variant.sku, 33)}`,
                        };
                        payload.lines.push(lineThree);
                    }
                }
                if (addAcquisitionOptionAsItem) {
                    const line: PrinterLine = {
                        blockType: 'textContent',
                        textContent: `${ensureSpacing('1', 5)}${ensureSpacing(
                            checkoutFlowAcquisitionOption.displayName,
                            33,
                        )}${ensureSpacing(acquisitionCost, 10, true)}`,
                        size: 'medium',
                        alignment: 'left',
                    };
                    payload.lines.push(line);
                }

                const totalLine: PrinterLine = {
                    blockType: 'textContent',
                    textContent: `- - - - - - -\n ${i18n.t('cardPayment.total')}: ${ensureSpacing(
                        total.toFixed(2),
                        10,
                        true,
                    )}\n`,
                    size: 'medium',
                    alignment: 'right',
                };
                payload.lines.push(totalLine);

                if (shopifyOrder && shopifyOrder.taxLines.length > 0) {
                    const taxLineTop: PrinterLine = {
                        blockType: 'textContent',
                        textContent: `- - - - - - -`,
                        size: 'medium',
                        alignment: 'left',
                    };
                    payload.lines.push(taxLineTop);

                    for (const taxLine of shopifyOrder.taxLines) {
                        const taxLineTop: PrinterLine = {
                            blockType: 'textContent',
                            textContent: `${taxLine.title} - ${taxLine.ratePercentage}% - ${taxLine.money}`,
                            size: 'medium',
                            alignment: 'left',
                        };
                        payload.lines.push(taxLineTop);
                    }

                    const taxLineBottom: PrinterLine = {
                        blockType: 'textContent',
                        textContent: `- - - - - - -`,
                        size: 'medium',
                        alignment: 'left',
                    };
                    payload.lines.push(taxLineBottom);
                }
            } else if (block.printerBlockDiscriminator === 'ORDER_REFERENCE') {
                const todoLine: PrinterLine = {
                    blockType: 'textContent',
                    textContent: `VivaWallet: ${vivaTransactionId}\nShopify: ${shopifyOrder?.id.replace(
                        'gid://shopify/Order/',
                        '',
                    )}`,
                    size: 'medium',
                    alignment: 'center',
                };
                payload.lines.push(todoLine);
            } else if (block.printerBlockDiscriminator === 'PAYMENT_DETAILS') {
                const paymentDetails: PrinterLine = {
                    blockType: 'paymentDetails',
                };
                payload.lines.push(paymentDetails);
            } else if (block.printerBlockDiscriminator === 'PRECUT') {
                const precut: PrinterLine = {
                    blockType: 'precut',
                };
                payload.lines.push(precut);
            } else if (block.printerBlockDiscriminator === 'SALES_ASSISTANT_DETAILS') {
                if (salesAssistant !== undefined) {
                    const salesAssistantDetails: PrinterLine = {
                        blockType: 'textContent',
                        textContent: `${i18n.t('cardPayment.salesAssistant')}: ${salesAssistant}`,
                        size: 'medium',
                        alignment: 'center',
                    };
                    payload.lines.push(salesAssistantDetails);
                }
            } else if (block.printerBlockDiscriminator === 'SPACER') {
                const spacer: PrinterLine = {
                    blockType: 'spacer',
                };
                payload.lines.push(spacer);
            } else if (block.printerBlockDiscriminator === 'FREE_TEXT') {
                let size: 'small' | 'medium' | 'large' | undefined = 'medium';
                let alignment: 'left' | 'center' | 'right' | undefined = 'center';

                if (block.size !== undefined) {
                    if (block.size === Size.Small) {
                        size = 'small';
                    } else if (block.size === Size.Large) {
                        size = 'large';
                    }
                }

                if (block.alignment !== undefined) {
                    if (block.alignment === Alignment.Left) {
                        alignment = 'left';
                    } else if (block.alignment === Alignment.Right) {
                        alignment = 'right';
                    }
                }

                const textContent: PrinterLine = {
                    blockType: 'textContent',
                    textContent: block.textContent ?? '',
                    size: size,
                    alignment: alignment,
                };

                if (textContent.textContent !== '') {
                    payload.lines.push(textContent);
                }
            } else if (block.printerBlockDiscriminator === 'TIMESTAMP') {
                const timeStamp: PrinterLine = {
                    blockType: 'textContent',
                    textContent: `${new Date().toISOString().split('T')[0]}, ${new Date().toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                    })}`,
                    size: 'medium',
                    alignment: 'center',
                };
                payload.lines.push(timeStamp);
            } else {
                const textContent: PrinterLine = {
                    blockType: 'textContent',
                    textContent: `Unknown type: ${block.printerBlockDiscriminator}`,
                    size: 'medium',
                    alignment: 'center',
                };
                payload.lines.push(textContent);
            }
        }
        return payload;
    }
}
